@charset "UTF-8";

@import "../../utilities/variables";
@import "../../utilities/mixins";

// 공통
// odc media query
@mixin odc-tablet {
  @media all and (min-width: 768px) and (max-width: 1460px) {
    @content;
  }
}

@mixin odc-tablet-s {
  @media all and (min-width: 768px) and (max-width: 900px) {
    @content;
  }
}

@mixin odc-tablet-l {
  @media all and (min-width: 768px) and (max-width: 1200px) {
    @content;
  }
}
// S : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
@mixin odc-hero {
  @media all and (min-width: 768px) and (max-width: 1920px) {
    @content;
  }
}

$size--desktop: 1920px;
$size--mobile: 360px;

// 단위 제거 함수
@function deUnit($value) {
  @return ($value / ($value * 0 + 1));
}
// 1920px 이하에서 사용될 vw-hero-pc(속성, 값)
@mixin vw-hero-pc($property, $pixel){
  @if length($pixel) == 1 {
      #{$property}: deUnit($pixel) * 100 / deUnit($size--desktop) * 1vw
  } @else if length($pixel) == 2 {
      #{$property}: deUnit(nth($pixel,1)) * 100 / deUnit($size--desktop) * 1vw
      deUnit(nth($pixel,2)) * 100 / deUnit($size--desktop) * 1vw
  } @else if length($pixel) == 3 {
      #{$property}: deUnit(nth($pixel,1)) * 100 / deUnit($size--desktop) * 1vw
      deUnit(nth($pixel,2)) * 100 / deUnit($size--desktop) * 1vw
      deUnit(nth($pixel,3)) * 100 / deUnit($size--desktop) * 1vw
  } @else if length($pixel) == 4 {
      #{$property}: deUnit(nth($pixel,1)) * 100 / deUnit($size--desktop) * 1vw
      deUnit(nth($pixel,2)) * 100 / deUnit($size--desktop) * 1vw
      deUnit(nth($pixel,3))* 100 / deUnit($size--desktop) * 1vw
      deUnit(nth($pixel,4)) * 100 / deUnit($size--desktop) * 1vw
  }
}

// 360px 이상에서 사용될 vw-hero-mo(속성, 값)
@mixin vw-hero-mo($property, $pixel){
  @if length($pixel) == 1 {
      #{$property}: deUnit($pixel) * 100 / deUnit($size--mobile) * 1vw;
  } @else if length($pixel) == 2 {
      #{$property}: deUnit(nth($pixel,1)) * 100 / deUnit($size--mobile) * 1vw
      deUnit(nth($pixel,2)) * 100 / deUnit($size--mobile) * 1vw;
  } @else if length($pixel) == 3 {
      #{$property}: deUnit(nth($pixel,1)) * 100 / deUnit($size--mobile) * 1vw
      deUnit(nth($pixel,2)) * 100 / deUnit($size--mobile) * 1vw
      deUnit(nth($pixel,3)) * 100 / deUnit($size--mobile) * 1vw;
  } @else if length($pixel) == 4 {
      #{$property}: deUnit(nth($pixel,1)) * 100 / deUnit($size--mobile) * 1vw
      deUnit(nth($pixel,2)) * 100 / deUnit($size--mobile) * 1vw
      deUnit(nth($pixel,3)) * 100 / deUnit($size--mobile) * 1vw
      deUnit(nth($pixel,4)) * 100 / deUnit($size--mobile) * 1vw;
  }
}
// E : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청

.odc-content {
  p {
    word-break: keep-all;
  }

  @include mobile {
    padding-bottom: 60px;
  }

  @include odc-tablet {
    overflow-x: hidden;
  }

  @include pc {
    padding-bottom: 120px;
  }

  // tooltip
  .tooltip-wrap {
    height: auto;
    line-height: inherit;
    p {
      color: #000;
    }
  }
  .tooltip-icon {
    position: relative;
    background-image: url("/lg5-common/images/ODC/icon-tooltip-gray.svg");

    @include mobile {
      top: 2px;
      width: 16px;
      height: 16px;
    }

    @include pc {
      top: 1px;
      width: 16px;
      height: 16px;
    }
  }

  .is-soldout {
    color: #666;
  }

  .is-mobile {
    @include mobile {
      display: block;
    }

    @include pc {
      display: none !important;
    }
  }

  .is-pc {
    @include mobile {
      display: none !important;
    }

    @include pc {
      display: block;
    }
  }

  // S:// BTOCSITE-54863 [소모품 사업팀] 소모품 메인페이지 검색영역 추가 요청 
  .KRP0031 {
    .btn-delete {
      position:absolute;
      top:calc(50% - 3px);
      right:72px;
      width:24px;
      height:24px;
      margin-top:-10px;
      background:url('/lg5-common/images/icons/btn-clear-32.svg') no-repeat 0 0/100%;
      @include mobile {
        top:calc(50% - 2px);
        right: 44px;
        width:24px;
        height:24px;
        background-size: 24px;
      }
    }
    .btn-search {
      position:absolute;
      top:0;
      right:0;
      width:32px;
      height:64px;
      background: url('/lg5-common/images/icons/icon-search-20.svg') no-repeat center/32px 32px;
      @include mobile {
        width:24px;
        height:38px;
        background-size: 24px;
      }
    }

    .search-wrap {
      text-align:center;
      padding:80px 0 0 0;
      @include tablet {
        padding: 64px 0 60px 0;
      }
      @include mobile {
        padding:30px 16px 0 16px;
      }
      .search-box {
        display:inline-block;
        position:relative;
        width:734px;
        //padding-bottom:56px;
        text-align:left;
        @media screen and (min-width: 768px) and (max-width:1024px) {
          width: 100%;
          padding:0 24px 44px 24px;
        }
        @include mobile {
          display:block;
          width:100%;
          padding-bottom:0;
        }
      }
      .search-input {
        position:relative;
        width: 734px;
        margin:0 auto;
        text-align:left;
        padding-right: 56px;
        @media screen and (min-width: 768px) and (max-width:1024px) {
          width: 100%;
        }
        @include mobile {
          width:100%;
          padding-right: 36px;
        }
        input[type=text] {
          width:100%;
          padding: 16px 64px 16px 40px;
          border-radius: 40px;
          background-color: #f3f3f3; // BTOCSITE-77410 [웹앱 접근성 개선활동] 전시_제품/소모품_에어컨/에어케어
          box-shadow: none;
          @include font(24px,32px);
          @include mobile {
            @include font(15px,22px);
            padding: 8px 48px 8px 26px;
          }
          &::placeholder {
            color: #666;
          }
        }
      }
      .search-more {
        // position: absolute;
        top: initial;
        left: 0;
        bottom: 0;
        width: 100%;
        transform: none;
        text-align: center;
        position:relative;
        margin-top:12px;
        @include mobile {
            margin-top: 12px;
        }
        .btn {
          display: inline-block;
          border: 0;
          border-radius: 0;
          padding:0 18px 0 0;
          background: url("/lg5-common/images/icons/icon-question-16.svg") no-repeat right center;
          @include font(14px,20px);
          font-weight: 400;
          text-decoration: underline;
          color:#000; 
          @include mobile {
            padding-right: 18px;
            @include font(13px,20px);
            background-size: 16px;
            color:#000;
            min-width: 64px;
          }
          &::before {
            content: none;
          }
        }
      }
      .search-error {
        display:none;
        margin-top:6px;
        color:#f00;
        @include font-small;
        vertical-align:top;
        &::before {
          content:' ';
          display:inline-block;
          width:20px;
          height:20px;
          margin:2px 4px 0 0;
          background: url("/lg5-common/images/icons/icon-error.svg") center no-repeat;
          vertical-align:top;
          @include mobile {
            margin-top:1px;
          }
        }
      }
    }
  }
  // E:// BTOCSITE-54863 [소모품 사업팀] 소모품 메인페이지 검색영역 추가 요청 
}

section[class*='odc'] {
  @include mobile {
    margin-top: 60px;
  }

  @include pc {
    margin: 120px auto 0;
    //margin: 44px auto 0;
    max-width: 1424px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .odc-product-name {
    @include textEllipsisClamp(2);
  }
}

// head (title, subtitle)
.odc-head {
  @include mobile {
    padding: 0 4px;
  }

  &__title {
    color: #000;
    font-weight: bold;
    @include mobile {
      font-size: 20px;
      line-height: 26px;
    }

    @include pc {
      font-size: 28px;
      line-height: 36px;
    }
  }

  &__subtitle {
    color: #666;
    @include mobile {
      margin-top: 4px;
      font-size: 15px;
      line-height: 21px;
    }

    @include pc {
      margin-top: 8px;
      font-size: 20px;
      line-height: 28px;
    }

    &--small {
      color: #666;

      @include mobile {
        margin-top: 2px;
        font-size: 14px;
        line-height: 20px;
      }

      @include pc {
        margin-top: 8px;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

// 전체보기
.odc-all-view {
  display: none;
  width: 100%;
  text-align: center;
  color: #000;

  @include mobile {
    padding: 10px 0;
    font-size: 13px;
    line-height: 19px;
  }
  @include pc {
    padding: 32px 0;
    font-size: 14px;
    line-height: 20px;
  }
  &__text {
    position: relative;
    padding-right: 17px;
    &:after {
      content: "";
      position: absolute;
      top: 4px;
      right: 0;
      width: 12px;
      height: 12px;
      background-image: url('/lg5-common/images/ODC/icon-arrow-down-black.svg');
      background-size: 12px 12px;
      background-repeat: no-repeat;
      background-position: 0 0;

      @at-root .is-active & {
        transform: rotate(180deg);
      }
    }
  }


}

// 더보기
.odc-more {
  text-align: right;
  &__link {
    display: inline-block;
    color: #666;
    background-repeat: no-repeat;
    background-image: url('/lg5-common/images/ODC/icon-arrow-right-gray.svg');

    &:hover,
    &:focus,
    &:active {
      color: #666;
    }

    @include mobile {
      padding-right: 14px;
      font-size: 13px;
      line-height: 17px;
      background-size: 11px 11px;
      background-position: right center;
    }

    @include pc {
      padding-right: 20px;
      font-size: 16px;
      line-height: 24px;
      background-size: 16px 16px;
      background-position: right 5px;
    }
  }
}

// 기획전, 프로모션, 이벤트 grid 리스트
.odc-grid-list {
  @include mobile {
    margin-top: 20px;
  }

  @include pc {
    margin-top: 32px;
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;

    @include pc {
      width: calc(100% + 24px);
      margin: 0 -12px;
    }

    @include odc-tablet-s {
      width: calc(100% + 10px);
      margin: 0 -5px;
    }
  }

  &__item {
    @include mobile {
      width: calc(50% - 6px);
      &:nth-child(n+3) {
        margin-top: 20px;
      }

      &:nth-child(even) {
        margin-left: 6px;
      }

      &:nth-child(odd) {
        margin-right: 6px;
      }
    }

    @include pc {
      width: calc(25% - 24px);
      margin: 0 12px;
    }

    @include odc-tablet-s {
      width: calc(33.33% - 10px);
      margin: 0 5px;
      &:nth-child(n+4) {
        display: none;
      }
    }
  }

  &__image {
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    @include mobile {
      border-radius: 8px;
      height: 30.556vw;
    }

    @include pc {
      border-radius: 16px;
      height: 220px;
    }
  }

  &__info {
    @include mobile {
      margin-top: 8px;
    }

    @include pc {
      margin-top: 16px;
    }
  }

  &__flag {
    position: relative;
    font-weight: 500;
    color: #da0f47;
    @include mobile {
      font-size: 12px;
      line-height: 17px;
    }

    @include pc {
      font-size: 14px;
      line-height: 20px;
    }

    & + & {
      @include mobile {
        margin-left: 4px;
        padding-left: 5px;
      }

      @include pc {
        margin-left: 6px;
        padding-left: 8px;
      }

      &:before {
        content: "";
        position: absolute;
        left: 0;
        display: inline-block;
        background-color: #ddd;

        @include mobile {
          top: 5px;
          width: 1px;
          height: 10px;
        }

        @include pc {
          top: 5px;
          width: 1px;
          height: 12px;
        }
      }
    }
  }

  &__title {
    max-width: 90%;
    font-weight: 500;

    @include mobile {
      margin-top: 2px;
      font-size: 14px;
      line-height: 20px;
    }

    @include pc {
      margin-top: 4px;
      font-size: 20px;
      line-height: 28px;
    }

    @include textEllipsisClamp(2);
  }

  &__date {
    font-weight: 500;
    color: #666;

    @include mobile {
      margin-top: 8px;
      font-size: 12px;
      line-height:17px;
    }

    @include pc {
      margin-top: 16px;
      font-size: 14px;
    }
  }
}

// badge
.odc-badge {
  display: inline-block;
  font-weight: 500;
  vertical-align: top;

  @include mobile {
    height: 20px;
    padding: 0 6px;
    margin-left: 6px;
    line-height: 20px;
    font-size:11px;
    border-radius: 4px;
  }

  @include pc {
    height: 26px;
    padding: 0 8px;
    margin-left: 12px;
    line-height: 26px;
    font-size: 14px;
    border-radius: 4px;
  }

  &--gray {
    background-color: #666;
    color: #fff;
  }
}

// ODCC0001 : 상단 hero 배너
.odc-hero {
  margin-top: 0 !important;
  position: relative;

  &.is-random {
    opacity: 0;
  }

  @include pc {
    padding: 0 !important;
    max-width: 100% !important;
  }
  // S : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
  @include mobile {
    padding-top:95%;
  }

  &__list {
    @include mobile {
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;  
    }
  }
  // E : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청

  &__track {
    overflow: hidden;
    height: 640px;
    // S : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
    @include odc-hero {
      @include vw-hero-pc(height,640px);
    }
    @include mobile{
      height: 100%;
    }
    // E : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
  }

  &__slide {
    position: relative;
    height: 640px;
    // S : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
    @include odc-hero {
      @include vw-hero-pc(height,640px);
    }
    @include mobile{
      height: 100%;
    }
    // E : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
  }

  &__image {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    img {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: auto;
      height: auto;
      min-width: 100%;
      min-height: 100%;
      max-width: none;

      @media screen and (min-width:1920px) {
        width: 100%;
        height: auto;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
      // S : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
      @include odc-hero {
        width:100%;
      }
      @include mobile {
        width:100%;
        top: auto;
        bottom: 0;
        left: 0;
        transform: none;
      }
      // E : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
    }
  }

  &__info {
    position: absolute;
    top: 120px;
    left: 0;
    right: 0;
    bottom: 120px;
    max-width: 1380px;
    margin: 0 auto;
    padding: 0 40px;
    transform: translateX(0);
    // S : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
    @include odc-hero {
      @include vw-hero-pc(top,120px);
      @include vw-hero-pc(bottom,120px);
      @include vw-hero-pc(max-width,1380px);
      @include vw-hero-pc(padding,0 40px);
    }
    @include mobile {
      @include vw-hero-mo(top,48px);
      @include vw-hero-mo(padding,0 20px);
    }
    // E : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청

    // @include odc-tablet-s {
    //   top: 120px;
    //   padding: 0 24px;
    // }
  }

  &__box {
    display:inline-block;
    &--black {
      color: #000;
    }
    &--white {
      color: #fff;
    }
  }

  &__title {
    color: inherit;
    @include pc {
      font-size: 48px;
      line-height: 56px;
      font-weight: 500;
    }
    // S : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
    @include odc-hero {
      @include vw-hero-pc(font-size,48px);
      @include vw-hero-pc(line-height,56px);
    }
    @include mobile{
      position: relative;
      @include vw-hero-mo(font-size,20px);
      @include vw-hero-mo(line-height,26px);
      font-weight: 700;
    }
    // E : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
  }

  &__subtitle {
    opacity: 0.7;
    @include pc {
      margin-top: 16px;
      font-size: 24px;
      line-height: 32px;
    }
    // S : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
    @include odc-hero {
      @include vw-hero-pc(margin-top,16px);
      @include vw-hero-pc(font-size,24px);
      @include vw-hero-pc(line-height,32px);
    }
    @include mobile{
      @include vw-hero-mo(margin-top,4px);
      @include vw-hero-mo(font-size,15px);
      @include vw-hero-mo(line-height,21px);
    }
    // E : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
  }

  &__link {
    display: inline-block;
    opacity: 0.7;
    @include pc {
      margin-top: 32px;
      font-size: 16px;
      line-height: 24px;
    }
    // S : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
    @include odc-hero {
      @include vw-hero-pc(margin-top,32px);
      @include vw-hero-pc(font-size,16px);
      @include vw-hero-pc(line-height,24px);
    }
    @include mobile {
      @include vw-hero-mo(margin-top,16px);
      @include vw-hero-mo(font-size,14px);
      @include vw-hero-mo(line-height,14px);
    }
    // E : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
    &:after {
      content: "";
      display: inline-block;
      @include pc {
        width: 16px;
        height: 16px;
        margin-left: 2px;
        vertical-align: -3px;
        background: url('/lg5-common/images/ODC/icon-arrow-right-black.svg') no-repeat right top;
        background-size: 14px 14px;
      }
      // S : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
      @include odc-hero {
        @include vw-hero-pc(width,16px);  
        @include vw-hero-pc(height,16px);
        @include vw-hero-pc(margin-left,2px);
        @include vw-hero-pc(vertical-align,-3px);
        @include vw-hero-pc(background-size,14px 14px);
      }
      @include mobile {
        background: url('/lg5-common/images/ODC/icon-arrow-right-black.svg') no-repeat right top;
        @include vw-hero-mo(vertical-align,-2px);
        @include vw-hero-mo(margin-left,2px);
        @include vw-hero-mo(width,12px);
        @include vw-hero-mo(height,12px);
        @include vw-hero-mo(background-size,11px 11px);
      }
      // E : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    &__info {
      width:100%;
    }
  }
  // S : BTOCSITE-77410 [웹앱 접근성 개선활동] 전시_제품/소모품_에어컨/에어케어
  .slide-arrow {
    @include mobile {
      display:none;
    }
    button {
      position:absolute;
      top:50%;
      transform:translateY(-50%);
      width:48px;
      height:48px;
      border-radius:100%;
      z-index:1;
      box-shadow:1px 3px 16px 0 rgba(0,0,0,.14);
      &.arrow-next {
        right:100px;
        background:url(/lg5-common/images/icons/arrow_right_32.svg) no-repeat center center/32px 32px;
        background-color:rgba(255,255,255,.85);
      }
      &.arrow-prev {
        left:100px;
        background:url(/lg5-common/images/icons/arrow_left_32.svg) no-repeat center center/32px 32px;
        background-color:rgba(255,255,255,.85);
      }
      @include odc-hero {
        @include vw-hero-pc(width,48px);
        @include vw-hero-pc(height,48px);
        @include vw-hero-pc(box-shadow,1px 3px 16px 0 rgba(0,0,0,.14));
        &.arrow-next {
          @include vw-hero-pc(right,100px);  
          @include vw-hero-pc(background-size,32px 32px);
        }
        &.arrow-prev {
          @include vw-hero-pc(left,100px);  
          @include vw-hero-pc(background-size,32px 32px);
        }
      }
    }
  }
  &__indicator {
    position: absolute;
    display: none;
    bottom: 32px;
    left: 0;
    right: 0;
    max-width: 1380px;
    margin: 0 auto;
    // S : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
    @include odc-hero {
      @include vw-hero-pc(bottom,32px);  
      @include vw-hero-pc(max-width,1380px);
    }
    @include mobile{
      max-width: none;
      @include vw-hero-mo(bottom,16px);
      @include vw-hero-mo(right,16px);
      @include vw-hero-mo(left,16px);
    }
    // E : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
  }

  &__controls {
    display:flex;
    align-items:center;
    position: absolute;
    bottom: 0;
    right: 24px;
    height: 32px;
    border-radius: 100px;
    background: rgba(0, 0, 0, 0.5);
    padding-right:12px;
    @include odc-hero {
      @include vw-hero-pc(right,24px);
      @include vw-hero-pc(height,32px);
      @include vw-hero-pc(padding-right,12px);
      @include vw-hero-pc(border-radius,100px);
    }
    @include mobile{
      right:0;
      @include vw-hero-mo(height,24px);
      @include vw-hero-mo(padding-right,6px);
      @include vw-hero-mo(border-radius,20px);
    }
    // E : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
  }

  &__inner {
    position: relative;
    text-align: center;
    font-size:0;
    @include mobile{
      @include vw-hero-mo(width,62px);
      @include vw-hero-mo(height,24px);
    }
    // E : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
  }

  &__paging {
    padding: 0 8px 0 5px;
    color: #fff;
    font-size: 14px;
    line-height: 32px;
    vertical-align:top;
    font-weight:700;
    color:#fff;
    // S : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
    @include odc-hero {
      @include vw-hero-pc(padding,0 8px 0 5px);
      @include vw-hero-pc(font-size,14px);
      @include vw-hero-pc(line-height,32px);
    }
    @include mobile{
      @include vw-hero-mo(padding,0 6px 0 4px);
      @include vw-hero-mo(font-size,11px);
      @include vw-hero-mo(line-height,24px);
    }
    // E : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
  }

  &__total {
    &:before{
      content: '/';
      margin: 0 3px;
      color: #fff;
      font-size:13px;
      font-weight:400;
      // S : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
      @include odc-hero {
        @include vw-hero-pc(margin,0 3px);
        @include vw-hero-pc(font-size,13px);
      }
      @include mobile {
        @include vw-hero-mo(margin,0 2px);
        @include vw-hero-mo(font-size,10px);
      }
      // E : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
    }
  }

  &__prev,
  &__next {
    @include pc {
      display:none!important;
    }
    position: absolute;
    overflow: hidden;
    // S : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
    @include mobile {
      @include vw-hero-mo(top,4px);
      @include vw-hero-mo(width,16px);
      @include vw-hero-mo(height,16px);
    }
    // E : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
    &:before{
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      box-shadow: none;
      // S : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
      background-size:100% 100%;
      @include odc-hero {
        @include vw-hero-pc(width,24px);
        @include vw-hero-pc(height,24px);
      }
      @include mobile{
        @include vw-hero-mo(width,16px);
        @include vw-hero-mo(height,16px);
      }
      // E : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
    }
  }

  &__prev {
    left: 0;
    // S : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
    &:before{
      background-image: url(/lg5-common/images/icons/btn-arr-24x24-lt.svg);
      @include mobile{
        @include vw-hero-mo(background-size,16px 16px);
      }
    }
    // E : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
  }
  &__next {
    right: 0;
    // S : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
    &:before{
      background-image: url(/lg5-common/images/icons/btn-arr-24x24-rt.svg);
      @include mobile{
        @include vw-hero-mo(background-size,16px 16px);
      }
    }
    // E : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
  }

  &__play {
    display: block !important;
    // position: absolute;
    // bottom: 0;
    // right: 0;
    margin-right: 0;
    width: 32px;
    height: 32px;
    // S : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
    @include odc-hero {
      @include vw-hero-pc(width,32px);
      @include vw-hero-pc(height,32px);
    }
    @include mobile{
      @include vw-hero-mo(width,24px);
      @include vw-hero-mo(height,24px);
    }
    // E : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
    &:before{
      content: "";
      display: block;
      width: 32px;
      height: 32px;
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: 100%;
      // S : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
      @include odc-hero {
        @include vw-hero-pc(width,32px);
        @include vw-hero-pc(height,32px);
      }
      @include mobile{
        @include vw-hero-mo(width,24px);
        @include vw-hero-mo(height,24px);
      }
      // E : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
    }
    &.play{
      &:before{
        background-image: url(/lg5-common/images/icons/btn-carousel-32-play.svg);
        @include mobile{
          @include vw-hero-mo(background-size,24px 24px); // BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
        }
      }
    }
    &.stop{
      &:before{
        background-image: url(/lg5-common/images/icons/btn-carousel-32-pause.svg);
        @include mobile{
          @include vw-hero-mo(background-size,24px 24px); // BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
        }
      }
    }
    // S : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
    // @media screen and (max-width: (1460px + 30px)) {
    //   right: 15px;
    //   margin-right: 0;
    //   @include mobile {
    //     right: 0;
    //   }
    // }
    // E : BTOCSITE-74763 [소모품 사업팀] 케어용품/소모품 페이지 히어로 배너 이미지 깨짐 현상 확인 요청
  }
  // E : BTOCSITE-77410 [웹앱 접근성 개선활동] 전시_제품/소모품_에어컨/에어케어
}

// ODCC0002 : 2depth 카테고리
.odc-sub-category {
  $root : &;
  @include pc{
    margin-top:100px !important; 
  }
  & + section[class*='odc'] {
    margin-top:48px;
  }

  @include mobile {
    display: block;
    //border-bottom: 5px solid #f6f6f6;
    margin-top:48px !important; 
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;
    @include mobile {
      margin-bottom: -1px;
    }

    @include pc {
      //padding:0 0 40px;
      //border-bottom:1px solid #eee;
      //padding:0 0 48px;
    }
  }
	&__itm {
		display:flex;
		overflow-x:auto;
		overflow-y:hidden;
		@include pc{
			gap:40px;
			padding-bottom:16px;
			&::-webkit-scrollbar {
				height:16px;
				background-color:transparent;
			}
			&::-webkit-scrollbar-thumb {
					border-radius:7px;
					background-color:rgba(0,0,0,1);
					border-top:4px solid rgba(255,255,255,1);
					border-bottom:4px solid rgba(255,255,255,1);
			}
			&::-webkit-scrollbar-track {
					border-radius:7px;
					background-color:rgba(0,0,0,0.2);
					border-top:4px solid rgba(255,255,255,1);
					border-bottom:4px solid rgba(255,255,255,1);
			}
		}
		@include mobile{
			gap:12px;
		}
		>li{
			@include pc{
				max-width:88px;
				width:88px;
			}
			@include mobile{
				max-width:64px;
				width:64px;
				&:first-of-type{
					margin-left:16px;
				}
				&:last-of-type{
					margin-right:16px;
				}
			}
			>a{
				display:block;
				.thumb{
          border-radius:100%;
          overflow:hidden;
					@include pc{
						width:88px;
            height:88px;
					}
					@include mobile{
						width:56px;
            height:56px;
					}
					>img{
						max-width:100%;
					}
				}
				.tit{
					display:block;
					margin-top:12px;
					font-size:16px;
					line-height:22px;
					text-align:center;
					@include mobile{
						margin-top:8px;
						font-size:12px;
						line-height:18px;
					}
				}
			}
		}
	}
}

// ODCC0003 : show room type 1
.odc-show-room-type1 {
  $root: &;
  @include mobile {
    padding: 0 16px;
  }
  // S : BTOCSITE-77410 [웹앱 접근성 개선활동] 전시_제품/소모품_에어컨/에어케어
  @include pc {
    display: flex;
    justify-content: flex-start;
    max-width: 1380px !important;
    padding: 40px !important;
    border-radius: 16px;
    background-color: #f7f7f7;
  }

  @include odc-tablet {
    margin: 120px 24px 0 !important;
  }

  @include odc-tablet-l {
    display: block;
    margin:120px auto 0 !important;
    padding:20px 24px 0!important;
    background-color:transparent;
  }

  @include odc-tablet-s {
    display: block;
    padding: 80px 24px 40px !important;
    // margin: 120px auto 0 !important;
  }
  .odc-head {
    @include pc {
      margin-left: 64px;
      padding-top: 40px;
      text-align: left;
      flex:1;
    }

    @include odc-tablet-l {
      .odc-head__title, .odc-head__subtitle {
        display:none;
      }
      margin-left: 0;
      margin-bottom: 32px;
    }

    @include odc-tablet-s {
      padding-top: 0;
    }
    @include mobile {
      display:none;
    }
    &.type-top {
      display:none;
      @include odc-tablet-l {
        display:block;
        .odc-head__title, .odc-head__subtitle {
          display:block;
        }
      }
      @include mobile {
        display:block;
      }
    }
  }
  // E : BTOCSITE-77410 [웹앱 접근성 개선활동] 전시_제품/소모품_에어컨/에어케어
  &__body {
    position: relative;
    overflow: hidden;

    @include mobile {
      margin-top: 20px;
    }

    @include pc {
      flex: none;
      width: 678px;
    }

    @include odc-tablet-l {
      width: 100%;
    }
  }

  &__list {
    overflow: hidden;

    @include pc {
      overflow: hidden;
      height: 400px;
      border-radius: 16px;
    }

    @include odc-tablet-l {
      height: auto;
    }

    @include mobile {
      border-radius: 8px;
      isolation: isolate;
    }
  }

  &__item {
    position: relative;

    @include pc {
      width: 678px;
      height: 400px;
    }

    @include odc-tablet-l {
      width: 100%;
      height: auto;
    }
  }

  &__image {
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
    }
  }

  &__mark {
    position: absolute;
    top: -100%;
    left: -100%;
    background-image: url('/lg5-common/images/ODC/icon-dot.svg');
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% 100%;

    @at-root .is-active & {
      background-image: url('/lg5-common/images/ODC/icon-dot-active.svg');
    }

    @include mobile {
      width: 5.556vw;
      height: 5.556vw;
    }

    @include pc {
      width: 24px;
      height: 24px;
    }
  }

  &__product {
    display: none;
    position: absolute;
    z-index: 1;
    width: calc(100% - 24px);
    left: 12px;
    bottom: 3.3334vw;
    padding: 12px 20px;
    background-color: #fff;
    border-radius: 8px;

    @at-root .is-active & {
      display: block;
    }

    @include pc {
      display: none !important;
    }
    // S : BTOCSITE-77410 [웹앱 접근성 개선활동] 전시_제품/소모품_에어컨/에어케어
    @include odc-tablet-l {
      display:block !important;
    }
    // E : BTOCSITE-77410 [웹앱 접근성 개선활동] 전시_제품/소모품_에어컨/에어케어
    &:hover,
    &:focus,
    &:active {
      background-color: #fff;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: -10px;
      width: 0px;
      height: 0px;
      border-bottom: 20px solid #fff;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      transform: rotate(90deg);
    }
  }

  &__tag {
    &:not(:first-child) {
      margin-left: 2px;
    }
    color: #da0f47;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    &:before {
      content: '#';
    }
  }

  .odc-product-name {
    font-size: 15px;
    font-weight: bold;
    line-height: 21px;
  }

  .odc-product-model {
    margin-top: 1px;
    font-size: 11px;
    line-height: 16px;
    color: #666;
  }

  .odc-product-price {
    margin-top: 4px;
    font-size: 15px;
    font-weight: 500;
    line-height: 21px;
  }

  // pc mark 상세 내용 분기
  &__detail {
    @include mobile {
      display: none;
    }
    @include pc {
      display: block;
      #{$root}__product {
        display: block !important;
        margin-top: 48px;
        position: static;
        background-color: transparent;
        padding: 0;
      }

      #{$root}__tag {
        font-size: 20px;
        line-height: 30px;
      }

      .odc-product-name {
        font-size: 26px;
        line-height: 38px;
      }

      .odc-product-model {
        margin-top: 2px;
        font-size: 20px;
        line-height: 30px;
      }

      .odc-product-price {
        margin-top: 16px;
        font-size: 26px;
        line-height: 38px;
      }
    }
    // S : BTOCSITE-77410 [웹앱 접근성 개선활동] 전시_제품/소모품_에어컨/에어케어
    @include odc-tablet-l {
      #{$root}__product {
        display:none !important;
      } 
    }
    // E : BTOCSITE-77410 [웹앱 접근성 개선활동] 전시_제품/소모품_에어컨/에어케어
  }

  &__indicator {
    display: none;
  }

  &__prev,
  &__next {
    position: absolute;
    top: 50%;
    z-index: 1;
    background-image: url('/lg5-common/images/ODC/icon-arrow-right-round.svg');
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% 100%;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    @include mobile {
      width: 32px;
      height: 32px;
      margin-top: -16px;
    }

    @include pc {
      top: 180px;
      width: 40px;
      height: 40px;
    }

    @include odc-tablet-l {
      top: 50%;
      margin-top: -36px;
    }
  }

  &__prev {
    transform: rotate(180deg);

    @include mobile {
      left: 6px;
    }

    @include pc {
      left: 16px;
    }
  }

  &__next {
    @include mobile {
      right: 6px;
    }

    @include pc {
      right: 16px;
    }

  }

  &__dots {
    display: none;
    @include mobile {
      margin-top: 7px; // BTOCSITE-77410 [웹앱 접근성 개선활동] 전시_제품/소모품_에어컨/에어케어
      font-size:0;
    }
    @include pc {
      margin-top: 12px; // BTOCSITE-77410 [웹앱 접근성 개선활동] 전시_제품/소모품_에어컨/에어케어
      // margin-bottom: -8px;
    }
    text-align: center;

    li {
      display: inline-block;
      vertical-align: top;
      // S : BTOCSITE-77410 [웹앱 접근성 개선활동] 전시_제품/소모품_에어컨/에어케어
      &.on {
        .odc-show-room-type1__dot {
          width:32px;
        }
      }
      // E : BTOCSITE-77410 [웹앱 접근성 개선활동] 전시_제품/소모품_에어컨/에어케어
    }
  }

  &__dot {
    // S : BTOCSITE-77410 [웹앱 접근성 개선활동] 전시_제품/소모품_에어컨/에어케어
    display: inline-flex;
    width:18px;
    height:18px;
    vertical-align: top;
    justify-content:center;
    align-items:center;
    &:before {
      content:'';
      display:inline-block;
      width:10px;
      height:10px;
      background-color:#8f8f8f;
      border-radius:50%;
      @include mobile {
        width:8px;
        height:8px;
      }
    }
    @at-root .on &:before {
      width:24px;
      height:10px;
      background-color:#000;
      border-radius:999px;
      @include mobile {
        width:20px;
        height:8px;
      }
    }
    // E : BTOCSITE-77410 [웹앱 접근성 개선활동] 전시_제품/소모품_에어컨/에어케어
  }

  // 케어용품/소모품 타입
  &--type2 {
    #{$root}__image {
      @include mobile {
        width: 69.445vw;
        height: 69.445vw;
      }

      @include pc {
        width: 500px;
        height: 400px;
      }
      margin: 0 auto;
    }

    #{$root}__item {
      @include mobile {
        padding: 15px 0 63px;
      }

      @include pc {
        background-color: #fff;
      }

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;

        @include mobile {
          background-color: rgba(0, 0, 0, 0.03);
        }
        @include pc {
          background-color: rgba(0, 0, 0, 0.01);
        }
      }
    }
  }
}

// ODCC0004 : show room type 2
.odc-show-room-type2 {
  $root : &;

  @include mobile {
    .ui_carousel_list.draggable {
      overflow: visible !important;
    }
  }

  .odc-head {
    @include mobile {
      padding: 0 20px;
    }
  }

  &__body {
    position: relative;
    @include mobile {
      margin-top: 20px;
      padding: 0 10px;
      overflow-x: auto;
      overflow-y: hidden;
    }

    @include pc {
      margin-top: 32px;
      width: calc(100% + 24px);
    }
  }

  &__list {
    @include pc {
      overflow: hidden;
      border-radius: 16px;
    }
  }

  &__track {
    display: flex;

    @include mobile {
      &:after {
        content: "";
        display: inline-block;
        width: 10px;
        flex: none;
      }
    }

    @include pc {
      margin: 0 -12px;
    }
  }

  &__item {
    overflow: hidden;
    flex: none;

    @include mobile {
      width: 50vw;
      margin: 0 6px;
      border-radius: 8px;
    }

    @include pc {
      width: calc(33.33% - 24px);
      margin: 0 12px;
      border-radius: 16px;
    }
    background-color:#f7f7f7; // BTOCSITE-77410 [웹앱 접근성 개선활동] 전시_제품/소모품_에어컨/에어케어

    &:hover,
    &:focus,
    &:active {
      background-color: rgba(0, 0, 0, 0.03);
    }

    @include mobile {
      &:only-child {
        width: calc(100% - 12px);
        margin: 0 6px;

        #{$root}__image {
          width: 100%;
          height: auto;
        }

        #{$root}__name {
          font-size: 13px;
          line-height: 19px;
        }

        #{$root}__price {
          font-size: 13px;
        }
      }
    }
  }

  &__image {
    overflow: hidden;
    @include mobile {
      width: 50vw;
      height: 31.945vw;

      img {
        width: 100%;
        height: auto;
      }
    }

    @include pc {
      position: relative;
      height: 284px;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: auto;
        height: auto;
      }
    }

  }

  &__info {
    @include mobile {
      padding: 12px 0 16px;
      margin: 0 16px;
    }

    @include pc {
      padding: 24px 8px 32px;
      margin: 0 24px;
    }

    @include odc-tablet-s {
      padding: 24px 0 32px;
      margin: 0 32px;
    }
  }

  .odc-product-name {
    font-weight: bold;

    @include mobile {
      font-size: 14px;
      line-height: 20px;
    }

    @include pc {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .odc-product-model {
    font-weight: 500;
    color: #666;

    @include mobile {
      margin-top: 1px;
      font-size: 11px;
      line-height: 16px;
    }

    @include pc {
      margin-top: 2px;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .odc-product-price {
    font-weight: 500;

    @include mobile {
      margin-top: 4px;
      font-size: 14px;
      line-height: 20px;
    }

    @include pc {
      margin-top: 16px;
      font-size: 20px;
      line-height: 24px;
    }
  }

  @include mobile {
    &__indicator {
      display: none !important;
    }
  }
  @include pc {
    &__indicator {
      position: absolute;
      display: block;
      width: 100%;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
    }

    &__prev,
    &__next {
      position: absolute;
      top: 50%;
      padding: 0;
      width: 72px;
      height: 72px;
      transform: translateY(-46%);
      overflow: hidden;

      &:before{
        content: '';
        display: block;
        width: 72px;
        height: 72px;
        box-shadow: none;
      }
    }

    &__prev {
      left: -35px;

      &:before{
        background-image: url(/lg5-common/images/ODC/icon-arrow-left-round-white.svg);
      }
    }

    &__next {
      right: -15px;

      &:before{
        background-image:  url(/lg5-common/images/ODC/icon-arrow-right-round-white.svg);
      }
    }

  }

  &--type2 {
    #{$root}__image {
      position: relative;
      text-align: center;
      background-color: #fff;

      @include mobile {
        padding: 2px;
      }

      @include pc {
        padding: 11px 0;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color:rgba(0,0,0,.03); // BTOCSITE-77410 [웹앱 접근성 개선활동] 전시_제품/소모품_에어컨/에어케어
      }

      img {
        @include mobile {
          width: 30.556vw;
          height: 30.556vw;
        }

        @include pc {
          max-width: 260px;
          height: auto
        }
      }

    }

    #{$root}__item {
      &:only-child {
        @include mobile {
          img {
            width: 175px;
            height: auto;
          }
        }
      }
    }

    #{$root}__info {
      border-top: 1px solid #ddd;
    }
  }
}

// ODCC0005 : 제품 추천 탭, 스펙형
.odc-recommend-tab {

  @include mobile {
    position: relative;
  }

  .odc-head {
    @include mobile {
      padding: 0 98px 0 20px;
    }
  }

  &__list {
    position: relative;
    @include mobile {
      margin-top: 4px;
      overflow: hidden;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: -24px;
        width: 64px;
        height: 100%;
        background: linear-gradient(to left, #fff, rgba(255, 255, 255, 0));
      }
    }

    @include pc {
      margin-top: 16px;
    }
  }

  &__inner {
    display: flex;
    // S : BTOCSITE-77410 [웹앱 접근성 개선활동] 전시_제품/소모품_에어컨/에어케어
    .tabs-wrap-new.flexible-tab-box {
      margin:0;
      .tabs {
        white-space: nowrap;
        padding: 0;
        width: auto !important;
        max-width: initial;
        overflow-x: initial;
        > li {
          margin:8px 4px 4px;
          &:first-child {
            margin-left:0;
          }
          > a {
            color:#000;
            font-size:16px; 
          }
          &.on {
            > a {
              background:#000;
              color:#fff;
              border:none;
              &:focus-visible {
                outline:3px dotted #000;
              }
            }
          }
          @include mobile {
            margin:4px 6px 4px 0;
            > a {
              height:32px;
              padding:0 12px;
              font-size:12px;
              line-height:30px;
              border-radius:16px;
            }
            &.on {
              > a {
                line-height:30px;
              }
            }
          }
        }
      } 
      .arrow-btn {
        position:absolute;
        top:0;
        width:100%;
        z-index:1;
        button {
          position:absolute;
          top:8px;
          width:40px;
          height:40px;
          &:before {
            content:'';
            position:absolute;
            top:50%;
            width:123px;
            height:100%;
            transform:translateY(-50%);
          }
          &:after {
            content:'';
            position:absolute;
            top:0;
            width:40px;
            height:40px;
          }
          &.prev {
            left:0;
            &:before {
              left:0;
              background-image:linear-gradient(90deg,#fff 56.38%,rgba(255,255,255,.84) 78.98%,rgba(255,255,255,0) 100%);
            }
            &:after {
              top:0;
              left:0;
              transform:rotate(180deg);
              background:url(/lg5-common/images/SLS/2024/btn_circle_arr.png) no-repeat 50% 50%/100% auto;
            }
          }
          &.next {
            right:0;
            &:before {
              right:0;
              background-image:linear-gradient(270deg,#fff 56.38%,rgba(255,255,255,.84) 78.98%,rgba(255,255,255,0) 100%);
            }
            &:after {
              top:0;
              right:0;
              background:url(/lg5-common/images/SLS/2024/btn_circle_arr.png) no-repeat 50% 50%/100% auto;
            }
          }
        }
        @include mobile {
          display:none;
        }
      } 
    }
    // E : BTOCSITE-77410 [웹앱 접근성 개선활동] 전시_제품/소모품_에어컨/에어케어
    @include mobile {
      overflow-x: auto;
      overflow-y: hidden;
      padding: 0 16px;
      &:after {
        content: "";
        display: inline-block;
        width: 16px;
        flex: none;
      }
    }
  }

  &__button {
    flex: none;
    color: #666;
    background-color: rgba(221, 221, 221, 0.3);

    @include mobile {
      & + & {
        margin-left: 4px;
      }

      height: 27px;
      padding: 0 12px;
      border-radius: 14px;
      font-size: 13px;
      line-height: 27px;
    }

    @include pc {
      & + & {
        margin-left: 8px;
      }

      height: 40px;
      line-height: 40px;
      padding: 0 16px;
      border-radius: 24px;
      font-size: 16px;
    }

    &.is-active {
      color: #fff;
      background-color: #008182;
    }
  }

  &__product {
    @include mobile {
      display: flex;
      flex-wrap: wrap;
      padding: 0 16px;
    }

    @include pc {
      display: flex;
      margin: 0 -12px;
    }

    @include odc-tablet-s {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;
    }
  }

  &__panel {
    display: none;
    @include mobile {
      margin-top: 16px;
    }

    @include pc {
      position: relative;
      margin-top: 28px;
    }

    &.is-active {
      display: block;
    }
  }

  &__item {
    @include mobile {
      width: calc(50% - 6px);
      &:nth-child(n+3) {
        margin-top: 8px;
      }

      &:nth-child(even) {
        margin-left: 6px;
      }

      &:nth-child(odd) {
        margin-right: 6px;
      }
    }

    @include pc {
      width: 25%;
      margin: 0 12px;
    }

    @include odc-tablet-s {
      width: calc(33.33% - 10px);
      margin: 0 5px;
      &:nth-child(n+4) {
        display: none;
      }
    }
  }

  &__info {
    @include mobile {
      padding: 12px 4px;
    }

    @include pc {
      padding: 16px 0;
    }
  }

  .odc-product-image {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    @include mobile {
      height: 100px;
    }

    @include pc {
      padding: 10px;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.03);
      @include mobile {
        border-radius: 8px;
      }

      @include pc {
        border-radius: 16px;
      }
    }

    img {
      @include mobile {
        width: 90px;
        height: 90px;
      }

      @include pc {
        width: 100%;
        height: auto;
        max-width: 200px;
      }
    }
  }

  .odc-product-name {
    font-weight: bold;
    @include mobile {
      font-size: 14px;
      line-height: 20px;
    }

    @include pc {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .odc-product-model {
    color: #666;
    @include mobile {
      margin-top: 1px;
      font-size: 11px;
      line-height: 16px;
    }

    @include pc {
      margin-top: 2px;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .odc-product-price {
    font-weight: 500;
    @include mobile {
      margin-top: 4px;
      font-size: 14px;
      line-height: 20px;
    }

    @include pc {
      margin-top: 16px;
      font-size: 20px;
      line-height: 28px;
    }
  }

  .odc-more {
    position: relative;
    @include mobile {
      margin-top:10px;
      margin-right:16px;
    }

    @include pc {
      margin-top:7px;
    }
  }
}

// ODCC0006 : 제품 추천 목록, 전시형
.odc-recommend-list {
  & + .odc-recommend-list {
      margin-top: 0;
  }

  @include mobile {
    padding: 32px 16px 16px;
  }

  @include pc {
    max-width: 100% !important;
    padding: 80px 0 56px;
    margin-top: 80px;
  }

  @include odc-tablet-s {
    padding: 80px 24px 56px
  }

  background-color: #f7f7f7;

  &__list,
  .odc-head {
    @include pc {
      max-width: 1460px;
      padding: 0 40px;
      margin: 0 auto;
    }

    @include odc-tablet {
      padding: 0;
    }
  }

  &__inner {
    @include mobile {
      margin-top: 20px;
    }

    @include pc {
      display: flex;
      flex-wrap: wrap;
      margin: 32px -12px 0;
    }

    @include odc-tablet-s {
      margin: 32px -10px 0;
    }
  }

  &__item {
    position: relative;
    background-color: #fff;

    @include mobile {
      display: flex;
      min-height: 112px;
      padding: 16px;
      border-radius: 8px;
    }

    @include pc {
      width: calc(33.33% - 24px);
      margin: 0 12px 24px;
      padding: 17px 32px 32px;
      border-radius: 16px;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: #fff;
    }

    @include mobile {
      &:not(:first-child) {
        margin-top: 12px;
      }
    }

    &:nth-child(n+4) {
      display: none;
    }

    @at-root .is-active &:nth-child(n+4) {
      @include mobile {
        display: flex;
      }
      @include pc {
        display: block;
      }
    }

    @include odc-tablet-s {
      width: calc(33.33% - 10px);
      padding: 17px 0 32px;
      margin: 0 5px 24px;
    }
  }

  &__info {
    @include mobile {
      padding: 8px 0 4px;
    }

    @include pc {
      margin-top: 17px;
    }

    @include odc-tablet-s {
      padding: 0 32px;
    }
  }

  .odc-product-image {
    @include mobile {
      flex: none;
      width: 80px;
      height: 80px;
      margin-right: 20px;
    }

    img {
      width: 100%;
      height: auto;

      @include pc {
        max-width: 250px;
      }

      @include odc-tablet-s {
        max-width: 100%;
      }
    }

    @include pc {
      text-align: center;
    }
  }

  .odc-product-name {
    font-weight: bold;
    @include mobile {
      font-size: 14px;
      line-height: 20px;
    }

    @include pc {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .odc-product-model {
    color: #666;

    @include mobile {
      margin-top: 1px;
      font-size: 11px;
      line-height: 16px;
    }

    @include pc {
      margin-top: 2px;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .odc-product-price {
    font-weight: 500;

    @include mobile {
      margin-top: 4px;
      font-size: 14px;
      line-height: 20px;
    }

    @include pc {
      margin-top: 16px;
      font-size: 20px;
      line-height: 28px;
    }

    @include odc-tablet-s {
      display: block;
    }
  }

  .odc-product-badge {
    @include odc-tablet-s {
      display: block;
      margin-top: 8px;
      .odc-badge {
        margin-left: 0;
      }
    }
  }

  .odc-all-view {
    margin-top: 8px;

    @include pc {
      margin-top: -24px;
      margin-bottom: -32px;
    }
  }
}

// ODCC0007 : 인기 제품
.odc-popular-product {
  position: relative;

  @include mobile {
    padding: 0 16px;
  }

  @include pc {
    display: flex;
    margin-top: 80px;
  }

  @include odc-tablet-s {
    display: block;
    padding-top:0;
  }

  .odc-head {
    @include pc {
      flex: none;
      width: 350px;
      padding-top:40px;
    }

    @include odc-tablet {
      width: 26vw;
    }
  }

  &__list {
    @include mobile {
      margin-top: 20px;
    }

    @include pc {
      display: flex;
      flex: 1;
    }

    @include odc-tablet-s {
      margin-top: 32px;
    }
  }

  &__item {
    position: relative;
    @include mobile {
      display: flex;
      padding: 5px 8px;
      border-bottom: 1px solid #f4f4f4;
    }

    @include pc {
      width: 33.33%;
      & + & {
        margin-left: 24px;
      }
    }

    @include odc-tablet-s {
      & + & {
        margin-left: 10px;
      }
    }
  }

  .odc-product-image {
    flex: none;
    position: relative;

    @include mobile {
      width: 106px;
      height: 80px;
      margin-right: 8px;
      padding-left:26px;
    }

    @include pc {
      text-align: center;
      padding: 38px 0;
      border-radius: 16px;
      box-shadow: 1px 1px 13px -3px rgba(0, 0, 0, 0.14);
    }

    img {
      width: 100%;
      height: auto;

      @include pc {
        max-width: 250px;
      }
    }
  }

  &__number {
    position: absolute;
    top: -5px;
    left:-8px;
    font-weight: 500;
    color:#fff;
    text-align: center;
    background: #da0f47;

    @include mobile {
      font-size: 12px;
      line-height: 22px;
      width:22px;
      height:22px;
    }

    @include pc {
      top: 0;
      left: 0;
      width:36px;
      height:36px;
      font-size: 18px;
      line-height:36px;

    }
  }

  &__info {
    @include mobile {
      padding-top: 12px;
      min-height: 80px;
    }

    @include pc {
      padding: 16px 8px;
    }
  }

  .odc-product-name {
    font-weight: bold;
    @include mobile {
      font-size: 14px;
      line-height: 20px;
    }

    @include pc {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .odc-product-model {
    color: #767676;

    @include mobile {
      font-size: 11px;
      line-height: 16px;
    }

    @include pc {
      margin-top: 2px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .odc-more {
    @include mobile {
      position: absolute;
      top:0;
      right:16px;
    }

    @include pc {
      position: absolute;
      top: 128px;
      left: 24px;
    }

    @include odc-tablet-s {
      top: 32px;
      right: 40px;
    }
  }
}

// ODCC0008 : 판매 베스트
.odc-best-product {
  position: relative;

  @include pc {
    display: flex;
    margin-top: 104px;
  }

  @include odc-tablet-s {
    display: block;
    padding-top:0;
  }

  .odc-head {
    @include mobile {
      padding: 0 20px;
    }

    @include pc {
      flex: none;
      width: 350px;
      padding-top:40px;
    }

    @include odc-tablet {
      width: 26vw;

    }
  }

  &__body {
    @include mobile {
      margin-top: 20px;
      padding: 0 10px;
      overflow-x: auto;
      overflow-y: hidden;
    }

    @include pc {
      flex: 1;
    }
  }

  &__list {
    display: flex;

    @include mobile {
      &:after {
        content: "";
        display: inline-block;
        width: 10px;
        flex: none;
      }
    }

    @include pc {
      flex-wrap: wrap;
      margin: 0 -12px;
    }

    @include odc-tablet-s {
      margin: 32px -5px 0;
    }
  }

  &__item {
    position: relative;
    overflow: hidden;
    @include mobile {
      flex: none;
      margin: 0 6px;
      border-radius: 8px;
    }

    @include pc {
      width: calc(33.33% - 24px);
      margin: 24px 12px 0;
      border-radius: 16px;
      &:nth-child(-n+3) {
        margin-top: 0;
      }
    }

    @include odc-tablet-s {
      width: calc(33.33% - 10px);
      margin: 10px 5px 0;
      border-radius: 16px;
      &:nth-child(-n+3) {
        margin-top: 0;
      }
    }
  }

  &__image {
    position: relative;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    @include mobile {
      width: 33.334vw;
      height: 36.112vw;
    }

    @include pc {
      height: 327px;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &__name {
    position: absolute;
    color: #fff;
    font-weight: 500;

    @include mobile {
      width: calc(100% - 32px);
      bottom: 16px;
      left: 16px;
      font-size: 16px;
      line-height: 20px;
    }

    @include pc {
      width: calc(100% - 24%);
      bottom: 14.32%;
      left: 12.221%;
      font-size: 24px;
      line-height: 32px;
    }
  }

  .odc-more {
    @include mobile {
      position: absolute;
      top: 0;
      right: 16px;
    }

    @include pc {
      position: absolute;
      top: 128px;
      left: 24px;
    }

    @include odc-tablet-s {
      top: 32px;
      right: 40px;
    }
  }
}

// ODCC0009 : 베스트 리뷰
.odc-best-review {
  @include mobile {
    padding: 0 16px;
  }

  &__list {
    @include pc {
      display: flex;
      margin-top: 32px;
    }
  }

  &__item {
    @include mobile {
      display: flex;
      padding: 20px 0;
      &:not(:first-child) {
        border-top: 1px solid #f4f4f4;
      }
    }

    @include pc {
      width: 33.33%;
      & + & {
        margin-left: 24px;
      }
    }

    @include odc-tablet-s {
      & + & {
        margin-left: 10px;
      }
    }
  }

  &__image {
    @include mobile {
      flex: none;
      border-radius: 8px;
      width: 120px;
      height: 120px;
      margin-right: 16px;
      overflow: hidden;
      background-size: cover;
    }

    @include pc {
      position: relative;
      height: 444px;
      border-radius: 16px;
      background-size: auto 100%;
      background-position: center 0;
    }
  }

  &__info {
    @include mobile {
      min-height: 120px;
      padding-top: 8px;
    }

    @include pc {
      margin-top: 16px;
    }
  }

  &__title {
    font-weight: 500;
    @include mobile {
      font-size: 16px;
      line-height: 22px;
      padding-right: 10px;
    }

    @include pc {
      font-size: 24px;
      line-height: 32px;
    }

    @include textEllipsisClamp(2);
  }

  &__row {
    @include mobile {
      margin: 8px -4px 0;
    }

    @include pc {
      margin: 12px -6px 0;
    }
  }

  &__tag {
    display: inline-block;
    font-weight: 500;

    &:before {
      content: '#';
    }

    @include mobile {
      margin: 0 2px;
      font-size: 12px;
      line-height: 17px;
    }

    @include pc {
      margin: 0 6px;
      opacity: 0.8;
      font-size: 20px;
      line-height: 30px;
    }
  }

  &__date {
    color: #767676;
    @include mobile {
      margin-top: 1px;
      font-size: 12px;
      line-height: 17px;
    }

    @include pc {
      margin-top: 4px;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

// 구매, 사용 가이드 링크 배너
.odc-guide-link {
  @include mobile {
    margin-top: 20px;
  }

  @include pc {
    display: flex;
    margin-top: 56px;
  }

  @include odc-tablet-l {
    display: block;
  }

  &__item {
    position: relative;
    display: flex;
    overflow: hidden;
    justify-content: center;
    flex-direction: column;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right center;

    @include mobile {
      padding: 0 20px;
      height: 110px;
      border-radius: 8px;
      & + & {
        margin-top: 12px;
      }
    }

    @include pc {
      flex: 1;
      padding: 0 40px;
      height: 180px;
      border-radius: 16px;
      & + & {
        margin-left: 24px;
      }
    }

    @include odc-tablet-l {
      & + & {
        margin-left: 0;
        margin-top: 24px;
      }
    }
  }

  &__info {
    position: relative;
    z-index: 1;
    max-width: 80%;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: auto;
    }
  }

  &__title {
    font-weight: bold;

    @include mobile {
      font-size: 15px;
      line-height: 21px;
    }

    @include pc {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__text {
    display: inline-block;
    opacity: 0.7;
    font-weight: 400;
    background-image: url('/lg5-common/images/ODC/icon-arrow-right-black.svg');
    background-repeat: no-repeat;

    @include mobile {
      margin-top: 4px;
      padding-right: 12px;
      background-position: right 4px;
      background-size: 10px 10px;
      font-size: 13px;
      line-height: 19px;
    }

    @include pc {
      margin-top: 8px;
      padding-right: 20px;
      background-position: right 6px;
      background-size: 14px 15px;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

// ODCC0010 : 기획전/프로모션
.odc-exhibition-promotion {
  padding: 0 16px;
}

// ODCC0011 : 이벤트
.odc-event {
  padding: 0 16px;
}

// 브랜드 연계배너
.odc-brand-banner {
  @include mobile {
    padding: 0 16px;
  }

  &__item {
    position: relative;
    display: flex;
    overflow: hidden;
    justify-content: center;
    flex-direction: column;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right center;

    @include mobile {
      padding: 20px;
      height: 130px;
      border-radius: 8px;

      & + & {
        margin-top: 12px;
      }
    }

    @include pc {
      height: 350px;
      padding: 0 96px;
      border-radius: 16px;

      & + & {
        margin-top: 40px;
      }
    }
  }

  &__info {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    max-width: 80%;
    color: #fff;

    @include mobile {
      left: 20px;
    }

    @include pc {
      left: 96px;
    }
  }

  &__logo {
    margin-bottom: 4px;
    img {
      width: auto;
      @include mobile {
        height: 15px;
      }
      @include pc {
        height: 26px;
      }
    }
  }

  &__title {
    font-weight: bold;
    @include mobile {
      margin-top: 2px;
      font-size: 16px;
      line-height: 22px;
    }

    @include pc {
      font-size: 32px;
      line-height: 40px;
    }
  }

  &__text {
    display: inline-block;
    position: relative;
    font-weight: 400;
    opacity:0.7;

    &:after {
      content:"";
      display: block;
      right:0;
      position: absolute;
      background-image: url('/lg5-common/images/ODC/icon-arrow-right-white.svg');
      background-repeat: no-repeat;
      background-size: 100% 100%;

      @include pc {
        top:5px;
        width:16px;
        height:16px;
      }
      @include mobile {
        top:4px;
        width:12px;
        height:12px;
      }
    }


    @include mobile {
      margin-top: 8px;
      padding-right: 12px;
      font-size: 13px;
      line-height: 19px;
    }

    @include pc {
      margin-top: 20px;
      padding-right: 20px;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

// ODCC0015 : 보유제품 연계 배너
.odc-my-product {
  //margin-top: 0 !important;
  //margin-bottom:60px !important;
  padding:0 24px 60px !important;
  margin:0 auto 80px;
  position:relative;
  &::after{
    margin:0 24px;
    height:1px;
    background:#eee;
    content:'';
    position:absolute;
    bottom:0;
    left:0;
    right:0;
  }
  @include mobile{
    //margin-bottom:24px !important;
    margin-top:28px !important;
    margin-bottom:0 !important;
    padding:0 !important;
    border-bottom:none;
    &::after{
      content:none;
    }
  }
  & + section[class*='odc'] {
    @include mobile {
			padding:0 0 24px;
    }

    @include pc {
      margin:120px auto 0;
    } 
  }

  &__banner {
    display: none;
		&.is-active {
			align-items:center;
			background:#f7f7f7;
			&:not(.is-my-product){
				display:flex;
				.odc-my-product__inner{
					padding-left:16px;
          display:flex;
          width:100%;
          justify-content:space-between;
          gap:16px;
          @include mobile{
            display:inline-block;
          }
				}
			}
			&.is-my-product{
				display:block;
        background:transparent;
        padding:0;
        
        @include mobile{
          background:#f7f7f7; // BTOCSITE-77410 [웹앱 접근성 개선활동] 전시_제품/소모품_에어컨/에어케어
          padding:24px 0;
        }
			}
			@include pc{
				padding:20px 40px;
				border-radius:12px;
			}
			@include mobile{
				//padding:24px 16px;
        padding:20px;
			}
		}
  }
	&__thumb{
    //border-radius:100%;
    overflow:hidden;
		@include pc{
			// width:100px;
			// height:100px;
      width:44px;
      height:42px;
		}
		@include mobile{
			width:48px;
			height:46px;
      flex-basis: 48px;
      flex-shrink: 0;
		}
		img{
			max-width:100%;
		}
	}

  &__title {
    &.wrap{
      display:flex;
      width:100%;
      justify-content:space-between;
      @include mobile{
        display:inline-block;
        padding:0 16px;
      }
    }
    @include mobile {
      font-size: 16px;
      font-weight:700;
      line-height: 22px;
      width:100%;
    }

    @include pc {
      br {
        display: none;
      }
      font-size: 18px;
      font-weight:500;
      line-height: 26px;
    }
  }

  &__link {
    display: inline-block;
    color: #666;
    background-image: url('/lg5-common/images/ODC/icon-arrow-right-gray.svg');
    background-repeat: no-repeat;
    &:hover{
      color:#666;
    }

    @include mobile {
      margin-top: 4px;
      padding-right: 18px;
      font-size: 12px;
      line-height: 18px;
      background-position: right center;
      background-size: 12px 12px;
      font-weight:400;
    }

    @include pc {
      padding-right: 20px;
      //margin-top: 8px;
      font-size: 16px;
      line-height: 24px;
      background-position: right 6px;
      background-size: 14px 14px;
      font-weight:400;
    }
  }

  &__count {
    color: $color-primary;
  }
	//보유제품 리스트
  &-list-wrap{
    padding-top:20px;
    position:relative;
    width: calc(100% + 24px);
    width:100%;
    @include mobile{
      padding:10px 16px 0;
      overflow-x:auto;
    }
    .odc-product-list{
      &.ui_static{
        margin-right:-24px;
      }
      &-track{
        margin:0 -12px;
        display:flex;
        // gap:24px;
        @include mobile{
          margin:0;
          &::after{
            content: "";
            display: inline-block;
            width:6px;
            flex: none;
          }
          
        }
        .odc-product-item{
          padding:28px 32px;
          border-radius:8px;
          background:#f7f7f7; // BTOCSITE-77410 [웹앱 접근성 개선활동] 전시_제품/소모품_에어컨/에어케어
          margin:0 12px;
          width: calc(50% - 24px);
          &:only-child{
            width:100%;
          }
          @include mobile{
            background:#fff;
            width:calc(100% - 30px);
            margin:0 10px 0 0;
            padding:20px 16px;
            flex:none;
            &:only-child{
              width:100%;
              margin:0;
            }
          }
        }
      }
      &-indicator{
        position:absolute;
        display:block;
        width:100%;
        z-index:1;
        top:50%;
        transform:translateY(-50%);
        .odc-product{
          &__prev,
          &__next {
            
            @include mobile{
              display:none;
            }
            position: absolute;
            margin-top:14px;
            padding:0;
            width:72px;
            height:72px;
            overflow: hidden;
            top: 50%;
            transform: translateY(-50%);
            &:before{
              content: '';
              display: block;
              width:72px;
              height: 72px;
              box-shadow: none;
              @include mobile{
                content:none;
              }
            }
          }
          &__prev {
            left:-36px;
            &:before{
              background-image: url('/lg5-common/images/ODC/icon-arrow-left-round-white.svg');
            }
          }
          &__next {
            right: -40px;
            &:before{
              background-image: url('/lg5-common/images/ODC/icon-arrow-right-round-white.svg');
            }
          }
        }
      }
    }
    .mp-box{
      display:flex;
      gap:32px;
      align-items: center;
      @include mobile{
        gap:12px;
        align-items: flex-start;
      }
      .thumb{
        width:88px;
        height:88px;
        border-radius:100px;
        background:#fff;
        position:relative;
        flex-basis: 88px;
        flex-shrink: 0;
        @include mobile{
          width:55px;
          height:55px;
          flex-basis:55px;
          flex-shrink: 0;
          background:#f7f7f7; // BTOCSITE-77410 [웹앱 접근성 개선활동] 전시_제품/소모품_에어컨/에어케어
        }
        img{
          position:absolute;
          top:50%;
          left:50%;
          width:48px;
          height:48px;
          transform:translate(-50%, -50%);
          @include mobile{
            width:30px;
            height:30px;
          }
          &.no-img {
            width:100%;
            height:auto;
            border-radius:100px;
            border:1px solid #aaa;
            @include mobile{
              width:54px;
            }
          }
        }
      }
    }
    .mp-info{
      .flag-wrap.bar-type{
        .flag{
          @include font(11px, 15px);
          font-weight:500;
          height:auto;
        }
      }
      .tit{
        margin:5px 0 10px;
        @include font(18px, 26px);
        font-weight:700;
        color:#000;
        @include mobile{
          margin:5px 0 8px;
          @include font(15px, 22px);
          word-break: break-all;
        }
      }
      .etc{
        @include font(14px, 20px);
        font-weight:400;
        color:#000;
        @include mobile{
          @include font(13px, 20px);
        }
      }
    }
    
  }

	.mp-list-group{
		margin-top:20px;
		@include mobile{
			margin-top:24px;
		}
		.mp-list{
			>li{
				margin-bottom:20px;
				@include mobile{
					margin-bottom:10px;
					width:100%;
				}
				&:last-of-type{
					margin-bottom:0;
				}
			}
			.mp-box{
				display:flex;
				position:relative;
				padding:32px;
				border-radius:8px;
				background:#fff;
				@include mobile{
					padding:16px;
				}
				&.new{
					position:relative;
					&:after{
						content:'';
						position:absolute;
						top:0;
						left:0;
						width:20px;
						height:20px;
						background:url('/lg5-common/images/MYC/badge-new_28.png') center no-repeat; 
						background-size:100%;
						@include mobile{
							width:14px;
							height:14px;
						}
					}
				}
				.thumb{
					position:absolute;
					width:88px;
					height:88px;
					background:#f7f7f7;
					border-radius:50%;
					overflow:hidden;
					img {
						position:absolute;
						top:50%;
						left:50%;
						width:48px;
						height:48px;
						transform:translate(-50%, -50%);
						&.no-img {
							top:0;
							left:0;
							width:88px;
							height:88px;
							transform:none;
						}
					}
					@include mobile{
						width:55px;
						height:55px;
						img {
							width:30px;
							height:30px;
							&.no-img {
								width:55px;
								height:55px;
							}
						}
					}
				}
				.mp-info{
					padding-left: 120px;
					width:100%;
					@include mobile{
						padding-left:67px;
					}
					.tit{
						font-size:18px;
						font-weight:600;
						line-height:1.56;
						@include textEllipsisClamp(2);
						@include mobile{
							margin-top:3px;
							font-size:15px;
							line-height:22px;
						}
					}
					.flag-wrap{
						+.tit{
								margin-top:5px;
						}
						.flag{
							font-size:11px;
							line-height:15px;
						}
					}
					.etc{
						position:relative;
						display:block;
						margin-top:10px;
						line-height:24px;
						@include mobile{
							margin-top:8px;
						}
						.model,.fmodel{
							display:block;
							width:100%;
							font-size:14px;
							@include mobile{
								font-size:13px;
								line-height:20px;
							}
							&.fmodel:before {
								content:'';
								display:inline-block;
								width:8px; height:9px;
								background:url('/lg5-common/images/MYC/icon-fmodel.svg') no-repeat;
								background-size:100%;;
								margin-right:4px;
							}
						}
						.tooltip-wrap{
							margin-top:0;
							padding:0 10px 0 5px;
							.tooltip-box{
									min-width: 402px;
									padding: 32px 42px 32px 32px;
									@include mobile{
										min-width: 276px;
										left: 50%;
										transform: translateX(-50%);
										right: 0;
										padding: 24px 0;
										text-align: center;
									}
								p{
									@include mobile{
										text-align:center;
									}
								}
							}
							.btn-close{
								top:16px;
								right:16px;
								width:16px;
								height:16px;
								&:after{
									width:16px;
									height:16px;
								}
								@include mobile{
									top:12px;
									right:12px;
								}
							}
						}
						.alarm{
							position:absolute;
							width:24px;
							height:24px;
							background:url('/lg5-common/images/icons/ico-alarm-48.svg') center no-repeat;
							background-size:24px 24px;
							top:0;
							right:0;
							em{
								position:absolute;
								top:-1px;
								right:-1px;
								width:14px;
								height:14px;
								background:#da0f47;
								border-radius:50%;
								font-size:10px;
								line-height:1.3;
								color:#fff;
								text-align:center;
							}
						}
					}
				}
			}
		}
	}
}

// 수동관리 연계 배너
.odc-banner {
  @include mobile {
    margin-top: 48px;
    padding: 0 16px;
  }

  @include pc {
    margin-top: 80px;
  }

  @include odc-tablet-l {
    display: block;
  }

  &__item {
    position: relative;
    display: flex;
    overflow: hidden;
    justify-content: center;
    flex-direction: column;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right center;

    @include mobile {
      padding: 20px;
      height: 110px;
      border-radius: 8px;

      & + & {
        margin-top: 12px;
      }
    }

    @include pc {
      flex: 1;
      padding: 40px;
      height: 180px;
      border-radius: 16px;

      & + & {
        margin-top: 24px;
      }
    }

  }

  &__info {
    position: relative;
    z-index: 1;
    max-width: 80%;

    @at-root .odc-banner__item--white {
      color: #fff;

      &:hover,
      &:active,
      &:focus {
        color: #fff;
      }
      // BTOCSITE-77410 [웹앱 접근성 개선활동] 전시_제품/소모품_에어컨/에어케어
      &:focus {
        outline-offset:2px;
      }
    }
  }

  &__title {
    font-weight: bold;

    @include mobile {
      font-size: 15px;
      line-height: 21px;
    }

    @include pc {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__text {
    display: inline-block;
    position: relative;
    opacity: 0.7;
    font-weight:400;
    // background-image: url('/lg5-common/images/ODC/icon-arrow-right-black.svg');
    // background-repeat: no-repeat;

    &:after {
      content:"";
      display: block;
      position: absolute;
      right:0;
      background: url('/lg5-common/images/ODC/icon-arrow-right-black.svg') no-repeat center center;
      background-size: 100% 100%;

      @include pc {
        top:5px;
        width:16px;
        height:16px;
      }
      @include mobile {
        top:4px;
        width:12px;
        height:12px;
      }
    }

    @include mobile {
      margin-top: 4px;
      padding-right: 12px;
      font-size: 13px;
      line-height: 19px;
    }

    @include pc {
      margin-top: 8px;
      padding-right: 20px;
      font-size: 16px;
      line-height: 24px;
    }

    @at-root .odc-banner__item--white & {
      &:after {
        background-image: url('/lg5-common/images/ODC/icon-arrow-right-white.svg');
      }
    }


  }
}